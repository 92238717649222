.leetCode {
    padding: 0 5%;
  }

  .banner{
    font-size: clamp(0.8rem, 0.76rem + 0.2vw, 1rem);

    hr {
        margin: 20px 0;
     }
}






