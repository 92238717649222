*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    
}


body{
    background: rgb(239, 240, 235);

}

code{
    font-family: 'Fira Code', monospace;
}